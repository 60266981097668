import * as React from "react"
import Layouts from "../components/layouts"
import "../assets/css/global.scss";
import contactBanner from "../assets/img/contact-us-bg.svg";

import Banner from "../components/banner";
import Data from "../data/technology.json";

// markup
const IndexPage = () => {

    return (
        <>
            <Layouts>
                <Banner bannerVector={contactBanner}>
                    <h2>Contact</h2>
                    <span>Get in touch with us to see how our company can help you grow
                    your business online.</span>
                </Banner>
                <div className="">
                    <div className="container">
                        <main>
                            <div className="">
                                <div className="container">
                                    <div className="page-section-top">
                                        <div className="row">
                                            <div className="col-lg-6 py-3">
                                                <h2 className="title-section">Get in Touch</h2>
                                                <div className="divider" />
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.<br /> Laborum ratione autem quidem veritatis!</p>
                                                <ul className="contact-list">
                                                    <li>
                                                        <div className="icon">
                                                            <span className="mai-map" /></div>
                                                        <div className="content">

                                                            Coworks, B-34,
                                                            2nd Cross Road,<br />Thillai Nagar East,Tiruchirappalli,
                                                            <br />Tamil Nadu, India - 620018.
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="icon"><span className="mai-mail" /></div>
                                                        <div className="content"><a href="#">contact@quadapp.in</a></div>
                                                    </li>
                                                    <li>
                                                        <div className="icon"><span className="mai-phone-portrait" /></div>
                                                        <div className="content"><a href="#">+91 9363572474 </a></div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-6 py-3">

                                                <h2 className="title-section">Drop Us a Line</h2>
                                                <div className="divider" />
                                                <form action="#">
                                                    <div className="py-2">
                                                        <input type="text" className="form-control" placeholder="Full name" />
                                                    </div>
                                                    <div className="py-2">
                                                        <input type="text" className="form-control" placeholder="Email" />
                                                    </div>
                                                    <div className="py-2">
                                                        <textarea rows={6} className="form-control" placeholder="Enter message" defaultValue={""} />
                                                    </div>
                                                    <div className="contect-btn">
                                                    <button type="submit" className="btn btn-primary rounded-pill mt-4 hover-shadow">Send Message</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" page-section">
                                        <h2 className="about-us ">Map Location</h2>
                                        <div class="divider mx-auto"></div>
                                        <div id="map-container-google-1" className="z-depth-1-half map-container" >
                                            <iframe src="https://maps.google.com/maps?q=Trichy+Coworks&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder={0} style={{ border: 0 }} allowFullScreen />
                                        </div>
                                    </div>

                                    <div className="row services-2-item page-section">
                                        <div className="col-xs-12 col-md-12 services-2-content">
                                            <h2 className="about-us ">You Will Get</h2>
                                            <div class="divider mx-auto"></div>
                                            <div className="section-type-feature-content">
                                                <div className="flex">
                                                    <div className="ml5">
                                                        <img src="https://redwerk.com/wp-content/uploads/2020/11/90_minutes.svg" /><br />
                                                        <span>15 minutes<br />of consultation</span>
                                                    </div>
                                                    <div>
                                                        <img src="https://redwerk.com/wp-content/uploads/2020/11/non-disclosure.svg" /><br />
                                                        <span>Strict<br />non-disclosure policy</span>
                                                    </div>
                                                    <div className="pdl">
                                                        <img src="https://redwerk.com/wp-content/uploads/2020/11/Timeline.svg" /><br />
                                                        <span>Timeline and<br /> cost evaluation</span>
                                                    </div>
                                                    <div>
                                                        <img src="https://redwerk.com/wp-content/uploads/2020/11/Suggestions.svg" /><br />
                                                        <span>Suggestions in<br /> terms of the tech stack</span>
                                                    </div>
                                                    <div>
                                                        <img src="https://redwerk.com/wp-content/uploads/2020/11/action.svg" /><br />
                                                        <span>Action plan to<br /> kick-start your project</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row services-2-item You-Will-Get">
                                        <div className="col-xs-12 col-md-12 services-2-content">
                                            <h2 className="about-us "> How We Start</h2>
                                            <div class="divider mx-auto"></div>

                                            <div className="section-type-feature-content">
                                                <div className="wrap-flex-timeline">
                                                    <div className="section-timeline">
                                                        <div className="timeline-title">Now</div>
                                                        <div className="timeline-circle" />
                                                        <div className="timeline-description">Tell us about your<br />
                                                            project, click Send</div>
                                                        <p /></div>
                                                    <div className="section-timeline">
                                                        <div className="timeline-title">in 24 hours</div>
                                                        <div className="timeline-circle2" />
                                                        <div className="timeline-description">We will contact<br />
                                                            you and help plan<br />
                                                            development effort</div>
                                                        <p /></div>
                                                    <div className="section-timeline">
                                                        <div className="timeline-title">in 1 week</div>
                                                        <div className="timeline-circle3" />
                                                        <div className="timeline-description">Sign master service<br />
                                                            agreement, schedule<br />
                                                            team allocation</div>
                                                        <p /></div>
                                                    <div className="section-timeline">
                                                        <div className="timeline-title">in 10 days</div>
                                                        <div className="timeline-circle4" />
                                                        <div className="timeline-description">Setup communication,<br />
                                                            prioritize tasks, set<br />
                                                            milestones</div>
                                                        <p /></div>
                                                    <div className="section-timeline">
                                                        <div className="timeline-title">in 1 month</div>
                                                        <div className="timeline-circle5" />
                                                        <div className="timeline-description">First tangible<br />
                                                            results</div>
                                                        <p /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}


                                    <div className="container page-section">
                                        <h2 className="about-us "> How We Start</h2>
                                        <div class="divider mx-auto"></div>
                                        <div className="row">
                                            <div className="col-12 hh-grayBox  pb20">
                                                <div className="row justify-content-between">
                                                    <div className="order-tracking completed">
                                                        <div className="timeline-title">Now</div>
                                                        <div className="is-complete">
                                                            <img className="h-w-s-img" src="../img/h-w-s-icon1.svg" alt="" />
                                                        </div>

                                                        <p className="c-p">Tell us about your <br />project, click Send</p>
                                                    </div>
                                                    <div className="order-tracking completed">
                                                        <div className="timeline-title">in 24 hours</div>
                                                        <div className="is-complete">
                                                            <img className="h-w-s-img" src="../img/h-w-s-icon2.svg" alt="" />
                                                        </div>

                                                        <p className="c-p">We will contact <br />you and help plan <br />development effort</p>
                                                    </div>
                                                    <div className="order-tracking completed">
                                                        <div className="timeline-title">in 10 day</div>
                                                        <div className="is-complete">
                                                            <img className="h-w-s-img" src="../img/h-w-s-icon3.svg" alt="" />
                                                        </div>

                                                        <p className="c-p">Sign master service <br />agreement, schedule<br />team allocation</p>
                                                    </div>
                                                    <div className="order-tracking completed">
                                                        <div className="timeline-title">in 2 week</div>
                                                        <div className="is-complete">
                                                            <img className="h-w-s-img" src="../img/h-w-s-icon4.svg" alt="" />
                                                        </div>

                                                        <p className="c-p">Setup communication, <br />prioritize tasks, set <br />milestones</p>
                                                    </div>
                                                    <div className="order-tracking completed">
                                                        <div className="timeline-title">in 45 days</div>
                                                        <div className="is-complete">
                                                            <img className="h-w-s-img" src="../img/h-w-s-icon5.svg" alt="" />
                                                        </div>

                                                        <p className="c-p">First tangible <br />results</p>
                                                    </div>
                                                    {/* <div className="order-tracking completed">
                                                        <span className="is-complete" />
                                                        <p>We will contact <br />you and help plan <br />development effort</p>
                                                    </div>
                                                    <div className="order-tracking">
                                                        <span className="is-complete" />
                                                        <p>Sign master service <br />agreement, schedule<br />team allocation</p>
                                                    </div>
                                                    <div className="order-tracking">
                                                        <span className="is-complete" />
                                                        <p>Setup communication, <br />prioritize tasks, set <br />milestones</p>
                                                    </div>
                                                    <div className="order-tracking">
                                                        <span className="is-complete" />
                                                        <p>First tangible <br />results</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </Layouts>
        </>
    )
}

export default IndexPage
