import React from "react";

export default function Banner({ bannerVector, children }) {
  return (
    <section id="intro" className="clearfix">
      <div className="container d-flex h-100">
        <div className="row justify-content-center align-self-center intro-info">
          <div className="col-md-6  order-md-first order-last">
            {children}
          </div>
          {/* <div className="col-md-6 intro-img order-md-last order-first">
                <img src={bannerVector} alt="" className=" banner-img img-fluid vert-move" />
              </div> */}
           <div className="col q ">Q</div>
            <div className="col a ">A</div>
            <div className="col u ">U</div>
            <div className="col p ">P</div>
            <div className="col a2 ">A</div>
            <div className="col p2 ">P</div>
            <div className="col d">D</div>
           </div>
        </div>
    </section>
  )
}