import React, { useState } from "react"
import Logo from "../../assets/img/logo.svg";
import { Link } from "gatsby";

export default function Header() {
  const [isActive, setIsActive]=useState(false);

const toggleMenu = () =>{

 !isActive ? setIsActive(true) : setIsActive(false)
}
  return (
    <>
      <div>
        <a href="#" className="scrollup">scrollup</a>

        <header id="header">
          <div className="container scroll">
            {/* <div id="topbar">
              <div className="social-links">
                <a href="tel:+919025995873">+91 90259 95873</a>
                <a href="mailto:info@quadapp.in"> info@quadapp.in</a>
                <a href="#"><span className="mai-logo-facebook-f" /></a>
                <a href="#"><span className="mai-logo-twitter" /></a>
                <a href="#"><span className="mai-logo-google" /></a>
                <a href="#"><span className="mai-logo-linkedin" /></a>
              </div>
            </div> */}
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container">
               <Link to="/"><img className="company-logo" src={Logo} alt="logo" /></Link> 
                <button className="navbar-toggler" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation"
                onClick={toggleMenu}>
                  <span className="navbar-toggler-icon" />
                </button>
                <div className={!isActive ? "navbar-collapse collapse": "navbar-collapse collapse show"} id="navbarContent">
                  <ul className="navbar-nav ml-lg-4 pt-3 pt-lg-0">
                    <li className="nav-item">
                      <Link to="/" className="nav-link" activeClassName="active">🏠 Home</Link>
                    </li>
                    <li className="nav-item ">
                      <Link to="/about" className="nav-link" activeClassName="active">👋 About Us</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/services" className="nav-link" activeClassName="active">🦸‍♂️ Services</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/technologies" className="nav-link" activeClassName="active">💡 Technologies</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/contact" className="nav-link" activeClassName="active">📞 Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>


      </div>
    </>
  )
}