import React, { useEffect, useState } from "react";

export default function Footer() {
  const [pathName, setPathName] = useState(null);
  useEffect(() => {
    setPathName(window.location.pathname);
  }, [])
  return (
    <>
      <footer className="page-footer">
        <div className="container">
          {/* {pathName !== "/contact" ?
            <div className="row justify-content-center mb-3">
              <div className="col-lg-3 ">
                <h5>Quadapp<span className="text-primary"> Technologies.</span></h5>

                <p>Coworks, B-34, 2nd Crosss Rd, East Thillai Nagar, tiruchirappalli, Tamil Nadu - 620018</p>
                <p><a href="#">contact@quadapp.in</a></p>
                <p><a href="#">+00 1122 3344 5566</a></p>


              </div>

           
              <div className="col-lg-9 ">
                <div id="map-container-google-1" className="z-depth-1-half map-container" >
                  <iframe src="https://maps.google.com/maps?q=Trichy+Coworks&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder={0} style={{ border: 0 }} allowFullScreen />
                </div>
              </div>
            </div> : false} */}




          <div className="row footer services-footer">

            <div className="col-sm-4 ">
              <h5>Quadapp <span class="box-heading">Technologies</span> Pvt Ltd</h5>

              <p>
                Coworks, B-34, 2nd Cross Road,
                <br />Thillai Nagar East,
                <br />Tiruchirappalli, Tamil Nadu,
                <br />India - 620018
              </p>
            </div>
            <div className=" col-sm-4  footer-contact">
              <h5>Contact Us</h5>
              <p><a href="mailto:info@quadapp.in">contact@quadapp.in</a> <br /> <a href="tel: +91 93635 72474">+91 93635 72474 </a></p>

            </div>
            <div className=" col-sm-4 ">
              <h5 className="social-head">Follow Social Media</h5>
              {/* <form action="#">
                  <input type="text" className="form-control" placeholder="Enter your mail.." />
                </form> */}
              <div className="sosmed-button mt-4 f-t-g-l-icons">
                <a href="#"><span className="mai-logo-facebook-f" /></a>
                <a href="#"><span className="mai-logo-twitter" /></a>
                <a href="#"><span className="mai-logo-instagram" /></a>
                <a href="#"><span className="mai-logo-linkedin" /></a>
              </div>
            </div>
            {/* <div className="col-sm-6 py-2 text-right">
                <div className="d-inline-block px-3">
                  <a href="#">Privacy</a>
                </div>
                <div className="d-inline-block px-3">
                  <a href="#">Contact Us</a>
                </div>
              </div> */}
          </div>
          <div className="">
            <p id="copyright">© 2021 <a href="https://quadapp.in/" className="footer-bottom"> Quadapp Technologies Pvt Ltd</a>. All rights reserved</p>
          </div>
        </div>
      </footer>
    </>
  )
}